import { FC } from "react";
// MUI
import { IconButton } from "@mui/material";
import { PhoneOutlined } from '@mui/icons-material';

interface Props {
  
}
 
const PhoneButton:FC<Props> = () => {
  return (
    <IconButton
      component="a" href="tel:+48786887050"
      size="large"
      color="primary"
      sx={{
        display: { xs: 'inline-flex', md: 'none' },
        position: 'fixed',
        bottom: 16,
        right: 16
      }}
    >
      <PhoneOutlined fontSize="large" />
    </IconButton>
  );
}
 
export default PhoneButton;