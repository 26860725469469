// Mui
import { createTheme } from '@mui/material/styles';
import { ukUA } from 'locales/ukUA';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      'sans-serif',
      '-apple-system'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#4196ff',
    },
    secondary: {
      main: '#262E36',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255,255,255,0.6)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontSize: '14px',
          lineHeight: '26px',
          fontWeight: 500,
          letterSpacing: '0.1px',
          transition: 'ease-in-out .3s',
          borderRadius: '30px',
          padding: '8px 20px',
          '@media (min-width: 600px)': {
            padding: '10px 33px'
          },
          '@media (min-width: 1240px)': {
            padding: '15px 50px'
          },
        },
        contained: {
          color: '#fff',
          backgroundColor: '#4196ff',
          '&:hover': {
            backgroundColor: '#1572f8',
          }
        },
        outlined: {
          color: '#CFB07A',
          border: '1px solid #CFB07A',
          '&:hover': {
            backgroundColor: 'rgba(#CFB07A, 0.1)',
          }
        },
        containedSecondary: {
          color: '#fff',
          backgroundColor: '#89c4e5',
          '&:hover': {
            backgroundColor: '#76a9c6',
          }
        },
        outlinedSecondary: {
          color: '#fff',
          border: '1px solid #fff',
        },
        textSecondary: {
          color: '#fff',
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid rgba(255,255,255,0.2)',
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: 'ease-in-out .3s',
          borderRadius: '50%',
          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
          }
        }, 
        colorPrimary: {
          color: '#fff',
          backgroundColor: 'rgba(65, 150, 255, 0.7)',
          '&:hover': {
            backgroundColor: '#1572f8',
          }
        }
      }
    }
  }
}, ukUA);

export default theme;
