import { FC } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// MUI
import { makeStyles } from '@mui/styles';
// Components
import Title from 'components/Title';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate, topAnimate } from 'utilities/Framer';

// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import './styles.css';

const items = ['service', 'repair', 'installation', 'maintenance', 'estimateQuote'];

const About:FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const itemsLabels:Record<string, string> = {
    service: t('homePage.about.service'),
    repair: t('homePage.about.repair'),
    installation: t('homePage.about.installation'),
    maintenance: t('homePage.about.maintenance'),
    estimateQuote: t('homePage.about.estimateQuote')
  }
  
  return (
    <motion.section
      data-container="about"
      className={classes.block}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.div variants={bottomAnimate} custom={1}>
        <Title>{t('homePage.about.aboutUs')}</Title>
      </motion.div>
      <div className={[classes.blockContent, 'container'].join(' ')}>
        <Swiper
          effect={'cube'}
          grabCursor={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          pagination={true}
          autoplay={true}
          modules={[EffectCube, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/images/about/1.jpeg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/about/2.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/about/3.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/about/4.jpg" alt="" />
          </SwiperSlide>
        </Swiper>
        <div className={classes.textContent}>
          <motion.h3
            className={classes.title}
            variants={topAnimate} custom={1}
          >{t('homePage.about.title1')}</motion.h3>
          <motion.h3
            className={classes.title}
            variants={topAnimate} custom={1}
          >{t('homePage.about.title2')}</motion.h3>
          <motion.p
            className={classes.subtitle}
            variants={topAnimate} custom={2}
          >{t('homePage.about.subtitle')}</motion.p>
          <div className={classes.items}>
            {items.map((item, i) => (
              <motion.div
                key={item}
                className={classes.item}
                variants={leftAnimate} custom={(i * 0.4) + 2}
              >
                <img src="/images/icons/check.png" alt={item} width="30" />
                <p className={classes.itemTitle}>{itemsLabels[item]}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default About;

const useStyles = makeStyles({
  block: {
    position: 'relative',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px'
    },
    '@media (min-width: 1240px)': {
      padding: '40px 32px'
    },
  },
  blockContent: {
    paddingTop: '20px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 600px)': {
      paddingTop: '32px',
      gap: '32px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
      paddingTop: '56px',
      gap: '40px',
    },
  },
  image: {
    width: '100%',
    maxWidth: '476px',
    objectFit: 'cover',
    '@media (min-width: 1240px)': {
      width: '50%',
    },
  },
  textContent: {
    width: '100%',
    '@media (min-width: 1240px)': {
      width: '50%',
    },
  },
  title: {
    color: '#0e073c',
    letterSpacing: '1px',
    fontSize: '28px',
    marginBottom: '20px',
    '@media (min-width: 600px)': {
      fontSize: '33px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '40px',
    },
  },
  subtitle: {
    color: '#696393',
    letterSpacing: '1px',
    fontSize: '16px',
    fontWeight: 500,
  },
  items: {
    paddingTop: '25px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    padding: '0 20px 20px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    '@media (min-width: 600px)': {
      width: '50%'
    }
  },
  itemTitle: {
    color: '#333',
    fontSize: '16px',
    fontWeight: 600
  }
});
