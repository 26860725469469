import i18n from "i18n";
i18n.setDefaultNamespace('common');

export const isRequired = {
  value: true,
  message: "This field is required"
};

const changeLanguage = () => {
  isRequired.message = i18n.t('validations.isRequired');
}

i18n.on('languageChanged', changeLanguage);