import config from 'config';

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
// i18next
import { useTranslation } from 'react-i18next';
// MUI
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
// Components
import Title from 'components/Title';
import StyledInput from 'components/StyledInput';
import Phone from 'components/Phone';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, topAnimate } from 'utilities/Framer';
import { useDispatch } from 'react-redux';
import { appActions } from 'store/app/appSlice';
import { isRequired } from 'utilities/Validation';

const { token, chatId } = config;

interface IForm {
  firstName: string;
  lastName: string;
  phone: string;
  subject: string;
  message: string;
}

const Contacts:React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { errors }, reset } = useForm<IForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      subject: '',
      message: '',
    }
  })

  const onSubmt = handleSubmit((data) => {
    setLoading(true);
    const text = `<b>Ім'я:</b> ${data.firstName} ${data.lastName}\n<b>Телефон</b>: +${data.phone}\n\n<b>Тема</b>: ${data.subject}\n<b>Повідомлення</b>:\n${data.message}`;
    axios.get(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=HTML&text=${encodeURIComponent(text)}`)
      .then(() => {
        dispatch(appActions.enqueueSnackbar({ message: t('notifications.messageSent'), options: { variant: 'success' } }));
        reset();
      })
      .finally(() => setLoading(false));
  })

  return (
    <motion.section
      data-container="contacts"
      className={classes.block}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className={[classes.blockContent, 'container'].join(' ')}>
        <motion.div variants={bottomAnimate} custom={1}>
          <Title>{t('homePage.contacts.getInTouch')}</Title>
        </motion.div>
        <motion.h3
          className={classes.title}
          variants={topAnimate} custom={1}
        >{t('homePage.contacts.title')}</motion.h3>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, boxShadow: 'rgba(0, 0, 0, 0.07) 0 0 15px' }}>
          <Box className={classes.contactBlock}>
            <h3 className={classes.contactBlockTitle}>{t('homePage.contacts.contactNow')}</h3>
            <div className={classes.contactBlockItem}>
              <h4 className={classes.contactBlockSubtitle}>{t('homePage.contacts.locationAddress')}</h4>
              <a href="https://maps.app.goo.gl/7Hj8ox6uKDLohuvJ8" target="_blank" rel="noreferrer" className={classes.contactBlockText}>
                Przy Bażantarni 8D, 02-793 Warszawa, Polska
              </a>
            </div>
            <div className={classes.contactBlockItem}>
              <h4 className={classes.contactBlockSubtitle}>{t('homePage.contacts.phoneNumber')}</h4>
              <a href="tel:+48786887050" className={classes.contactBlockText}>+48 786 887 050</a>
            </div>
            <div className={classes.contactBlockItem}>
              <h4 className={classes.contactBlockSubtitle}>{t('homePage.contacts.emailAddress')}</h4>
              <a href="mailto:termoclinic@gmail.com" className={classes.contactBlockText}>termoclinic@gmail.com</a>
            </div>
          </Box>
          <Box className={classes.formBlock}>
            <h3 className={classes.formBlockTitle}>{t('homePage.contacts.getInTouch')}</h3>
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="firstName"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label={t('homePage.contacts.firstName')}
                        required
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="lastName"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label={t('homePage.contacts.lastName')}
                        required
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="phone"
                    rules={{ required: isRequired }}
                    render={({ field:{ value, onChange } }) => (
                      <Phone
                        value={value}
                        onChange={onChange}
                        label={t('homePage.contacts.phone')}
                        required
                        error={Boolean(errors.phone)}
                        helperText={errors.phone?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control} name="subject"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label={t('homePage.contacts.subject')}
                        required
                        error={Boolean(errors.subject)}
                        helperText={errors.subject?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control} name="message"
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                      <StyledInput
                        {...field}
                        label={t('homePage.contacts.message')}
                        multiline
                        rows={4}
                        required
                        error={Boolean(errors.message)}
                        helperText={errors.message?.message || ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={onSubmt}
                  >
                    {t('homePage.contacts.getAction')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </div>
    </motion.section>
  );
}

export default Contacts;

const useStyles = makeStyles({
  block: {
    position: 'relative',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px'
    },
    '@media (min-width: 1240px)': {
      padding: '40px 32px'
    },
  },
  blockContent: {
    paddingTop: '20px',
    '@media (min-width: 600px)': {
      paddingTop: '32px',
    },
    '@media (min-width: 1240px)': {
      paddingTop: '56px',
    },
  },
  title: {
    color: '#0e073c',
    letterSpacing: '1px',
    fontSize: '34px',
    textAlign: 'center',
    margin: '20px 0 40px 0',
    '@media (min-width: 600px)': {
      fontSize: '40px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '60px',
    },
  },
  contactBlock: {
    width: '100%',
    backgroundColor: '#0e073b',
    padding: '40px 30px',
    '@media (min-width: 1240px)': {
      padding: '60px 40px',
      width: 'calc(100% / 3)',
    },
  },
  contactBlockTitle: {
    marginBottom: '40px',
    fontSize: '24px',
    fontWeight: 600,
    color: '#fff',
    '@media (min-width: 600px)': {
      fontSize: '30px',
    },
  },
  contactBlockItem: {
    padding: '30px 0',
    borderBottom: 'rgba(255, 255, 255, 0.07) solid 1px',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  contactBlockSubtitle: {
    fontSize: '18px',
    color: '#a39ccf',
    fontWeight: 600,
  },
  contactBlockText: {
    fontSize: '16px',
    color: '#5c548f',
    fontWeight: 500,
    transition: 'color 0.3s',

    '&:hover': {
      color: '#e1dfef'
    }
  },
  formBlock: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.07) 0 0 15px',
    padding: '40px 30px',
    '@media (min-width: 1240px)': {
      padding: '60px 40px',
      width: 'calc(100% / 3 * 2)',
    },
  },
  formBlockTitle: {
    marginBottom: '40px',
    fontSize: '24px',
    fontWeight: 600,
    color: '#0e073c',
    '@media (min-width: 600px)': {
      fontSize: '30px',
    },
  },
});
