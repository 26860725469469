import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
// Components
import Logo from 'components/Logo';
import LanguageSelect from 'components/LanguageSelect';
// MUI
import { Box, IconButton, Typography } from '@mui/material';
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  PhoneOutlined
} from '@mui/icons-material';
// styles
import classes from './styles.module.scss';
// i18next
import { useTranslation } from 'react-i18next';

interface INavLink {
  id: string;
  to?: string;
}

const navLinks:INavLink[] = [
  { id: 'main' },
  { id: 'about' },
  { id: 'services' },
  { id: 'contacts' },
  // { id: 'calendar', label: 'Календар', to: '/calendar' },
];

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1
};

const Header:React.FC = () => {
  const { t } = useTranslation('common');
  const { scrollTo } = useScrollIntoView();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenMenu = () => { setOpen(!open) };

  const observerCallback = useCallback((entries:any) => {
    entries.forEach((entry:any, index:number) => {
      if ( entry.isIntersecting ){
        const id = entry.target.dataset.container;
        if ( id ) setActiveMenuItem(id);
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (pathname !== '/') {
      setActiveMenuItem(null);
      return;
    }

    const blocks = navLinks.map((navLink:INavLink) => document.querySelector(`[data-container="${navLink.id}"]`));
    const observer = new IntersectionObserver(observerCallback, options);
    blocks.forEach((block:Element | null) => {
      if ( block ) observer.observe(block)
    });
    return () => {
      observer.disconnect();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleScrollTo = (block: string) => {
    if (pathname !== '/') {
      navigate('/');
    }
    setOpen(false);
    setTimeout(() => {
      scrollTo(block);
    }, 100)
  }

  const navLinksLabels:Record<string, string> = {
    about: t('components.header.about'),
    services: t('components.header.services'),
    contacts: t('components.header.contacts'),
    gallery: 'Gallery',
    blog: 'Blog',
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    body[0].className = open ? 'page' : '';
  }, [open]);

  return (
    <header className={classes.header}>
      <div className={[classes.header_content, 'container'].join(' ')}>
        <Logo />
        <IconButton onClick={handleOpenMenu} className={classes.menuBtn}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <ul className={classNames(classes.menu, { [classes['menu--active']]: open })}>
          {navLinks.slice(1).map((navLink: INavLink) => (
            navLink.to ? (
              <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: pathname === navLink.to })}>
                <NavLink to={navLink.to} onClick={() => setOpen(false)}>{navLinksLabels[navLink.id]}</NavLink>
              </li>
            ) : (
              <li key={navLink.id} className={classNames(classes.item, { [classes['item--active']]: activeMenuItem === navLink.id })} onClick={() => handleScrollTo(navLink.id)}>{navLinksLabels[navLink.id]}</li>
            )
          ))}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flex: 1, py: 1, justifyContent: 'center', alignItems: 'flex-end', width: '100%', borderTop: '1px solid rgba(136, 136, 156, 0.3)' }}>
            <LanguageSelect showValue />
          </Box>
        </ul>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          <LanguageSelect />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: '#9993bf', transition: 'color 0.3s', '&:hover': { color: '#4196ff' } }}>
            <PhoneOutlined />
            <Typography
              component="a" href="tel:+48786887050"
              sx={{ color: 'inherit' }}
            >
              +48 786 887 050
            </Typography>
          </Box>
        </Box>
      </div>
    </header>
  );
}

export default Header;
