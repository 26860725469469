import { FC, Fragment, useEffect } from 'react';
// Components
import Banner from './Banner';
// Utilities
import { scrollToTop } from 'utilities/Utilities';
import About from './About';
import Services from './Services';
import Contacts from './Contacts';

const HomePage:FC = () => {
  useEffect(() => scrollToTop(), []);

  return (
    <Fragment>
      <Banner />
      <About />
      <Services />
      <Contacts />
    </Fragment>
  );
}

export default HomePage;
