import React from 'react';
import { NavLink } from 'react-router-dom';
// Utilities
import { scrollToTop } from 'utilities/Utilities';

const Logo:React.FC = () => {
  return (
    <NavLink to="/" onClick={scrollToTop}>
      <img
        src="/logo.png"
        alt="Termo clinic"
        style={{ height: '45px', objectFit: 'contain', cursor: 'pointer' }}
      />
    </NavLink>
  );
}

export default Logo;
