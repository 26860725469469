import React, { useMemo } from 'react';
// i18next
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
// MUI
import { makeStyles } from '@mui/styles';
// Components
import Title from 'components/Title';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, leftAnimate, topAnimate } from 'utilities/Framer';

const Services:React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const items = useMemo(() => ([
    { id: 1, title: t('homePage.services.item1title'), text: t('homePage.services.item1text') },
    { id: 2, title: t('homePage.services.item2title'), text: t('homePage.services.item2text') },
    { id: 3, title: t('homePage.services.item3title'), text: t('homePage.services.item3text') },
    // eslint-disable-next-line
  ]), [i18n.language]);
  
  return (
    <motion.section
      data-container="services"
      className={classes.block}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className={[classes.blockContent, 'container'].join(' ')}>
        <motion.div variants={bottomAnimate} custom={1}>
          <Title>{t('homePage.services.ourServices')}</Title>
        </motion.div>
        <motion.h3
          className={classes.title}
          variants={topAnimate} custom={1}
        >{t('homePage.services.title')}</motion.h3>
        <div className={classes.items}>
          {items.map((item, i) => (
            <motion.div
              key={item.id}
              className={classes.item}
              variants={leftAnimate} custom={i + 2}
            >
              <img src={`/images/icons/service-${item.id}.png`} alt={item.title} width="120" />
              <h3 className={classes.itemTitle}>{item.title}</h3>
              <p className={classes.itemSubtitle}>{item.text}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
}

export default Services;

const useStyles = makeStyles({
  block: {
    backgroundColor: '#e3e7ee',
    position: 'relative',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px'
    },
    '@media (min-width: 1240px)': {
      padding: '40px 32px'
    },
  },
  blockContent: {
    paddingTop: '20px',
    '@media (min-width: 600px)': {
      paddingTop: '32px',
    },
    '@media (min-width: 1240px)': {
      paddingTop: '56px',
    },
  },
  title: {
    color: '#0e073c',
    letterSpacing: '1px',
    fontSize: '34px',
    textAlign: 'center',
    margin: '20px 0',
    '@media (min-width: 600px)': {
      fontSize: '40px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '60px',
    },
  },
  items: {
    paddingTop: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '40px'
  },
  item: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'rgb(0, 0, 0, 0.1) 0 9px 15px',
    borderBottom: '#d3e7ff solid 9px',
    '@media (min-width: 600px)': {
      width: 'calc(50% - 20px)'
    },
    '@media (min-width: 1240px)': {
      width: 'calc(100% / 3 - 27px)',
      padding: '35px',
    },
  },
  itemTitle: {
    color: '#0e073c',
    fontSize: '25px',
    fontWeight: 600,
    '@media (min-width: 1240px)': {
      fontSize: '30px',
    },
  },
  itemSubtitle: {
    color: '#696393',
    fontSize: '16px'
  }
});
