import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Reducers
import appReducer from 'store/app/appSlice';
// middlewares
import errorMiddleware from 'middlewares/errorMiddleware';

const rootReducer = combineReducers({
  app: appReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(errorMiddleware)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch']; 
