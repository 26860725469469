import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// translation files
import common_en from 'translations/en/translation.json';
import common_es from 'translations/es/translation.json';
import common_ru from 'translations/ru/translation.json';
import common_pl from 'translations/pl/translation.json';
import common_ua from 'translations/ua/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pl",
    whitelist: ["pl", "ua", "ru", "en", "es"],
    debug: false,
    detection: {
      order: ["localStorage", "cookie"],
      chaches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pl: {
        common: common_pl
      },
      ua: {
        common: common_ua
      },
      ru: {
        common: common_ru
      },
      en: {
        common: common_en
      },
      es: {
        common: common_es
      },
    },
  });

export default i18n;
