import React, { useEffect, useState } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// MUI
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { opacityAnimate } from 'utilities/Framer';
import useScrollIntoView from 'hooks/useScrollIntoView';

export const btnAnimate = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, delay: custom * 0.4 }, 
  })
}

const Banner:React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { scrollTo } = useScrollIntoView();

  const [ativeBannerImg, setActiveBannerImg] = useState<number>(1);

  useEffect(() => {
    const bgInterval = setInterval(() => {
      setActiveBannerImg(prev => prev === 3 ? 1 : prev + 1);
    }, 3000)

    return () => {
      clearInterval(bgInterval);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <motion.section
      data-container="main"
      className={classes.block}
    >
      {(new Array(4).fill('')).map((image: string, index: number) => (
        <img
          key={`banner-image-${index}`} alt=""
          src={`images/banner/${index + 1}.jpg`} className={classes.bannerImg} style={{ opacity: ativeBannerImg === (index + 1) ? 1 : 0 }}
        />
      ))}
      <motion.div
        className={[classes.blockContent, 'container'].join(' ')}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2 }}
      >
        <motion.h2
          className={classes.title}
          variants={opacityAnimate}
          custom={1}
        >
          {t('homePage.banner.title')}
        </motion.h2>
        <motion.div
          className={classes.buttons}
          variants={btnAnimate}
          custom={3}
        >
          <Button variant="contained" onClick={() => scrollTo('services')}>
            {t('homePage.banner.ourServices')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => scrollTo('contacts')}
          >
          {t('homePage.banner.contactNow')}
          </Button>
        </motion.div>
      </motion.div>
    </motion.section>
  );
}

export default Banner;

const useStyles = makeStyles({
  bannerImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: '65% 50%',
    zIndex: -1,
    transition: 'opacity 2s',
  },
  block: {
    position: 'relative',
    padding: '16px',
    height: 'calc(100vh - 85px)',
    '@media (min-width: 600px)': {
      padding: '32px'
    },
    '@media (min-width: 1240px)': {
      padding: '40px 32px'
    },
  },
  blockContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '20px',
  },
  title: {
    color: '#0e073c',
    letterSpacing: '3px',
    textShadow: '0 0 3px #171f27',
    fontSize: '40px',
    lineHeight: '45px',
    zIndex: 1,
    position: 'relative',
    '@media (min-width: 600px)': {
      fontSize: '50px',
      lineHeight: '55px'
    },
    '@media (min-width: 1240px)': {
      fontSize: '80px',
      lineHeight: '85px',
    },
  },
  buttons: {
    paddingTop: '30px',
    '@media (min-width: 600px)': {
      paddingTop: '50px'
    },
    display: 'flex',
    gap: '20px'
  }
});
