import { withStyles } from "@mui/styles";
import { Input } from "./Controls";

export default withStyles({
  root: {
    background: '#f7f9ff',
    border: 'none',
    outlined: 'none',
    '& label': {
      color: '#9d97c5',
    },
    '& label.Mui-focused': {
      color: '#4196ff',
    },
    '& label.Mui-disabled': {
      color: '#999',
    },
    '& .MuiOutlinedInput-root': {
      color: '#4196ff',
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#4196ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4196ff',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(Input);
