import React, { Fragment, ReactNode, useState } from 'react';
// MUI
import { MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// i18next
import { useTranslation } from 'react-i18next';

export const countries = [
  { label: 'Polski', value: 'pl', imgUrl: '/images/flags/pl.png' },
  { label: 'Українська', value: 'ua', imgUrl: '/images/flags/ua.png' },
  { label: 'Русский', value: 'ru', imgUrl: '/images/flags/ru.png' },
  { label: 'English', value: 'en', imgUrl: '/images/flags/us.png' },
  { label: 'Español', value: 'es', imgUrl: '/images/flags/es.png' },
]

type Props = {
  showValue?: boolean;
}

const LanguageSelect:React.FC<Props> = ({ showValue = false }) => {
  const classes = useStyles();
  const { i18n } = useTranslation('common');

  const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'en');

  const handleLanguage = (event:any) => {
    const { value:lang } = event.target;
    setLang(lang);
    i18n.changeLanguage(lang);
  }

  return (
      <TextField
        variant="outlined"
        style={{ border: 'none' }}
        className={classes.input}
        size="small"
        select
        onChange={handleLanguage}
        value={lang}
        SelectProps={{
          IconComponent: "span",
          renderValue: (value: any):ReactNode => {
            const country = countries.find(country => country.value === value);
            return (
              <Tooltip title="Choose your language">
                <Typography component="span" sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                  <img src={country?.imgUrl} className={classes.icon} alt={lang} />
                  {showValue && (
                    <Fragment>&nbsp;&nbsp;{country?.label}</Fragment>
                  )}
                </Typography>
              </Tooltip>
            )
          }
        }}
      >
        {countries.map(country => (
          <MenuItem key={country.value} value={country.value}>
            <img src={country.imgUrl} className={classes.icon} alt={country.label} />&nbsp;&nbsp;{country.label}
          </MenuItem>
        ))}
      </TextField>
  );
}

export default LanguageSelect;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  icon: {
    width: '32px',
    height: '32px',
    objectFit: 'cover'
  }
});
