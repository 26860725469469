import { FC, Fragment } from 'react';
// Components
import Footer from 'components/Footer';
import Header from 'components/Header';
import Notifications from 'components/Notifications';
// Routing
import AppRouting from './App.routing';
import PhoneButton from 'components/PhoneButton';

const App:FC = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <AppRouting />
      </main>
      <Footer />
      {/* <GoogleAnalytics /> */}
      <PhoneButton />
      <Notifications />
    </Fragment>
  );
}

export default App;
