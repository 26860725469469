import { FC } from 'react';
// Mui
import { Box, Typography } from '@mui/material';

type Props = {
  children: string;
}

const Title:FC<Props> = ({
  // Props
  children
}) => {
  return (
    <Box>
      <Typography
        sx={{
          color: '#746f96',
          // lineHeight: '116.7%',
          fontSize: '21px',
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: '-0.5px',
          textAlign: 'center',
          marginBottom: '20px',
          '@media (min-width: 600px)': {
            fontSize: '22px',
          },
          '@media (min-width: 1240px)': {
            fontSize: '24px',
          },
        }}
      >
        {children}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '3px' }}>
        <Box sx={{ width: '4px', height: '4px', bgcolor: '#4196ff', borderRadius: '50%' }} />
        <Box sx={{ width: '4px', height: '4px', bgcolor: '#4196ff', borderRadius: '50%' }} />
        <Box sx={{ width: '40px', height: '4px', bgcolor: '#4196ff', borderRadius: '4px', mx: 1 }} />
        <Box sx={{ width: '4px', height: '4px', bgcolor: '#4196ff', borderRadius: '50%' }} />
        <Box sx={{ width: '4px', height: '4px', bgcolor: '#4196ff', borderRadius: '50%' }} />
      </Box>
    </Box>
  )
}

export default Title;
