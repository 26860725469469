import React from 'react';
// MUI
import { Typography } from '@mui/material';
// styles
import classes from './styles.module.scss';

const Footer:React.FC = () => {
  return (
    <footer className={classes.footer}>
      <div className={[classes.footerContent, 'container'].join(' ')}>
        <Typography sx={{ fontSize: '18px', color: '#696393' }}>{`© ${new Date().getFullYear()} Termo Clinic`}</Typography>
      </div>
    </footer>
  );
}

export default Footer;
