interface IConfig {
  token: string;
  chatId: string;
}

const config:IConfig = {
  token: '7452433202:AAGubLRFXtZC8GIAl_RKKLmndrjBvIw2Z8s',
  chatId: '-1002233704591',
};


export default config;
